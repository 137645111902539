<template>
    <b-modal
        id="ModalCreateDatasource"
        size="xl"
        title="Ajouter une source de données"
        header-bg-variant="kalkin-1"
        header-text-variant="light"
        hide-footer
    >
        <FormDatasource
            v-bind:is-edit-mode="false"
            v-bind:projectId="projectId"
            @saved="onSaved"
            class="mb-4"
        />
    </b-modal>
</template>
<script>
import FormDatasource from "@/components/model/datasource/FormDatasource";
export default {
    components: {
        FormDatasource,
    },
    props: {
        projectId: {
            required: true,
            type: [String, Number],
        },
    },
    methods: {
        onSaved() {
            this.$bvModal.hide("ModalCreateDatasource");
            this.$emit("saved");
        },
    },
};
</script>
