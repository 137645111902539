<template>
    <div class="k-flex-col">
        <div class="k-flex-row k-flex-fill">
            <LabelledInputText
                label="Nom"
                placeholder="Nom de la source"
                :required="true"
                v-model="localDatasource.name"
                @validated="isNameValid = $event"
            />

            <LabelledInput label="Type" :required="true">
                <b-form-select
                    v-model="selectedDatasourceType"
                    :options="dataSourceTypeOptions"
                    :disabled="isEditMode"
                    @change="onSelectDST"
                >
                    <template #first>
                        <b-form-select-option :value="null" disabled>
                            -- Type de la source --
                        </b-form-select-option>
                    </template>
                </b-form-select>
            </LabelledInput>
        </div>

        <div class="text-right font-italic" v-show="isEditMode">
            <small>
                <b-icon-info-circle />
                Création le
                {{ localDatasource.createdAt | dateFromISO }} ~ Dernière
                modification le
                {{ localDatasource.updatedAt | dateFromISO }}
            </small>
        </div>

        <GenericForm
            :key="selectedDatasourceType"
            v-bind="genericFormConfig"
            @validated="isGFValid = $event"
            @updated="localDatasource.data = $event"
        />

        <div class="k-flex-center">
            <b-button
                size="sm"
                variant="kalkin-2"
                @click="save"
                :disabled="!isValid"
            >
                Enregistrer les modifications
            </b-button>
        </div>
    </div>
</template>
<script>
import Vue from "vue";
import LabelledInput from "@/components/ui/item/LabelledInput";
import LabelledInputText from "@/components/ui/item/LabelledInputText";
import GenericForm from "@/components/form-generator/GenericForm";
import Formatter from "@/tools/format";

const defaultDatasource = {
    data: {},
};

export default {
    components: {
        LabelledInput,
        LabelledInputText,
        GenericForm,
    },

    props: {
        projectId: { type: [String, Number], required: true },
        datasource: { type: Object, required: false },
    },

    data: function() {
        return {
            isEditMode: this.datasource != null,
            dataSourceTypes: [],
            internalSDST: null,
            isNameValid: this.datasource != null,
            isGFValid: true,
            localDatasource: Vue.util.extend(
                {},
                this.datasource || defaultDatasource,
            ),
        };
    },

    computed: {
        isValid() {
            return (
                this.isNameValid &&
                this.isGFValid &&
                this.selectedDatasourceType
            );
        },

        selectedDatasourceType: {
            get() {
                if (this.isEditMode) {
                    let dst = this.localDatasource.DataSourceType || {};
                    return dst.id;
                } else {
                    return this.internalSDST;
                }
            },
            set(value) {
                this.internalSDST = value;
            },
        },

        dataSourceTypeOptions() {
            if (this.isEditMode) {
                let dst = this.localDatasource.DataSourceType || {};
                return [
                    {
                        text: dst.name,
                        value: dst.id,
                    },
                ];
            } else {
                return this.dataSourceTypes.map(dst => {
                    return {
                        text: dst.name,
                        value: dst.id,
                    };
                });
            }
        },

        genericFormConfig() {
            let config = {};
            try {
                let format;
                if (this.isEditMode) {
                    format = this.datasource.DataSourceType.data.format
                        .datasource;
                } else {
                    format = this.dataSourceTypes.find(
                        d => d.id === this.internalSDST,
                    ).data.format.datasource;
                }
                config = {
                    schema: {
                        ...Formatter.convertCOREtoFORM(format),
                    },
                    model: this.localDatasource.data,
                };
            } catch (error) {
                //nothing
            }
            return config;
        },
    },

    methods: {
        onSelectDST() {
            this.localDatasource.data = {};
        },

        async save() {
            if (this.isEditMode) {
                await this.$store.dispatch("datasource/editDataSource", {
                    projectId: this.projectId,
                    datasourceId: this.localDatasource.id,
                    name: this.localDatasource.name,
                    data: this.localDatasource.data,
                });
            } else {
                await this.$store.dispatch("datasource/createDataSource", {
                    projectId: this.projectId,
                    name: this.localDatasource.name,
                    dataSourceTypeId: this.internalSDST,
                    data: this.localDatasource.data,
                });
            }
            this.$emit("saved");
        },
    },

    async beforeMount() {
        if (!this.isEditMode) {
            this.dataSourceTypes = await this.$store.dispatch(
                "datasource/getDataSourceTypes",
            );
        }
    },
};
</script>
