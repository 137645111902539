<template>
    <div class="card k-card-2">
        <div class="k-flex-row">
            <div>
                <small class="text-kalkin-1">Nom</small>
                <div>
                    <b>{{ datasource.name }}</b>
                </div>
            </div>
        </div>

        <div>
            <small class="text-kalkin-1">SIT</small>
            <div>
                {{ datasource.DataSourceType.name }}
            </div>
        </div>
        <div>
            <small class="text-kalkin-1">Nombre de sélections</small>
            <div>
                {{ datasource.DataSelections.length }}
            </div>
        </div>

        <div class="flex-grow-1" />

        <div class="k-flex-center">
            <b-button
                size="sm"
                variant="kalkin-1"
                @click="onGoDetails(datasource.id)"
            >
                <b-icon-list-ul /> Voir les détails
            </b-button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        datasource: { type: Object, required: true },
        projectId: { type: [String, Number], required: true },
    },

    methods: {
        onGoDetails(id) {
            this.$router.push({
                name: "AdminDataSource",
                params: {
                    datasourceId: id,
                    projectId: this.projectId,
                },
            });
        },
    },
};
</script>
