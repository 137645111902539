<template>
    <div class="k-page-flex">
        <div class="k-page-title">
            <h1>Sources de données du projet</h1>
            <b-button
                v-b-modal.ModalCreateDatasource
                size="sm"
                variant="kalkin-2"
            >
                <b-icon-plus-circle /> Nouvelle source de données
            </b-button>
        </div>

        <GridCardBasic :data="datasources" empty="Aucune source de données">
            <template v-slot="{ data }">
                <CardDataSourceAdmin
                    :datasource="data"
                    :project-id="projectId"
                />
            </template>
        </GridCardBasic>

        <ModalCreateDatasource
            :projectId="projectId"
            @saved="fetchDatasources"
        />
    </div>
</template>

<script>
import GridCardBasic from "@/components/ui/GridCardBasic";
import CardDataSourceAdmin from "@/components/model/datasource/CardDataSourceAdmin";
import ModalCreateDatasource from "@/components/model/datasource/ModalCreateDatasource";

export default {
    components: {
        GridCardBasic,
        ModalCreateDatasource,
        CardDataSourceAdmin,
    },

    data: function() {
        return {
            projectId: this.$route.params.projectId,
            datasources: [],
        };
    },

    methods: {
        async fetchDatasources() {
            this.datasources = await this.$store.dispatch(
                "datasource/getDataSources",
                { projectId: this.projectId },
            );
        },

        async onDataSourceUpdated() {
            this.datasources = [];
            await this.fetchDatasources();
        },
    },

    async beforeMount() {
        await this.fetchDatasources();
    },
};
</script>
